.ArticleMain {
    width: 60%;
    text-align: left;
    padding-bottom: 8em;
}

.ArticleMain h1 {
    text-align: center;
    font-weight: 500;
}

.ArticleMain p {
    font-size: 1.2rem;
    font-weight: 100;
}

.ArticleMain h2 {
    font-size: 1.8rem;
    font-weight: 300;
}

.ContentArea {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ArticleMain a {
    color: #26afaf;
}

.ArticleMain .MetroLine {
    margin-left: 4px;
    display: inline-block;
    height: 1.2em;
    width: 1.5em;
    line-height: 1.25em;
    font-family: sans-serif;
    font-size: 100%;
    text-align: center;
    color: #FFFFFF;
    border-radius: 2px;
}

.ArticleMain select {
    border: gray solid 1px;
    border-radius: 10px;
    height: 2em;
    font-size: 1rem;
    width: 12em;
    font-family: sans-serif;
    padding-left: 1em;
}

.ArticleMain select {
    border: gray solid 1px;
    border-radius: 10px;
    height: 2rem;
    font-size: 1rem;
    width: 12em;
    font-family: sans-serif;
    padding-left: 1em;
}

.ArticleMain input {
    border: gray solid 1px;
    border-radius: 10px;
    height: 2rem;
    font-size: 1rem;
    width: 16em;
    font-family: sans-serif;
    padding-left: 1em;
}

.ArticleMain button {
    border: lightgray solid 1px;
    background-color: #0864da;
    color: white;
    border-radius: 1.2rem;
    height: 2.4rem;
    font-size: 1rem;
    width: 5em;
    font-family: sans-serif;
    font-weight: 800;
}

.ArticleMain button:hover {
    background-color: #064698;
}

@media (max-width: 880px) {
    .ArticleMain {
        width: 80%;
    }
}