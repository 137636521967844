.Nav {
    font-family: sans-serif;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Banner {
    transition: linear 0.4s;
    background: url("/bg_pic.png");
    background-size: cover;
    color: white;
    font-weight: 800;
    letter-spacing: 2px;
    width: 100%;
}

.Navigation {
    display: flex;
    flex-direction: row;
    border-bottom: 1px black solid;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px;
}

.Navigation a {
    text-decoration: none;
    font-size: 1.3rem;
    color: black;
    letter-spacing: 3px;
    padding: 0 5%;
    border-left: 1px lightgray solid;
    font-weight: 200;
}

.Navigation a:hover {
    background-color: #efefef;
}

.Navigation a.selected {
    font-weight: 400;
}

.Navigation a:last-child {
    border-right: 1px lightgray solid;
}

.Title {
    margin-block-start: 30px;
    margin-block-end: 0;
    font-size: 50px;
}

.Subtitle {
    margin-block-start: 0;
    margin-block-end: 30px;
    font-size: 20px;
}

.Banner:hover {
    transition: linear 0.4s;
    padding-top: 20px;
}

.Description {
    margin-top: 20px;
    height: 1px;
    transition: linear 0.4s;
    font-weight: 300;
}

.Banner:hover .Description {
    display: block;
    height: 8em;
}

.Description p {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

@media (max-width: 880px) {
    .Navigation a {
        padding: 0 0.4rem;
    }
}