.RightScrollBar {
    width: 10%;
    bottom: 0;
}

.ReturnButton {
    position: fixed;
    font-size: 0.8rem;
    font-weight: 100;
    bottom: 3rem;
    font-family: sans-serif;
    cursor: pointer;
}